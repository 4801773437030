<template>
  <div class="mangguo">
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT717_3_01.jpg">
    <div class="scroll_tips">
    <div class="scroll_box">
      <span class="scroll_item">176****6142抢到了</span
      ><span class="scroll_item">131****7394抢到了</span
      ><span class="scroll_item">155****4549抢到了</span
      ><span class="scroll_item">130****5436抢到了</span
      ><span class="scroll_item">156****0054抢到了</span
      ><span class="scroll_item">171****5245抢到了</span
      ><span class="scroll_item">175****6540抢到了</span
      ><span class="scroll_item">185****0974抢到了</span>
    </div>
    </div>
    <div class="form">
      <img class="one" v-show="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT711_1.gif" >
      <van-field
        :class="{ 'one-cell': num == 1}"
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="phoneChange"
      />
      <template>
        <img class="two" v-show="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT712_1.gif" >
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入验证码"
          :class="['input-code', { 'two-cell': num == 2}]"
        >
          <template slot="right-icon">
            <div class="code" v-show="sendAuthCode" @click="getAuthCode">
              获取验证码
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
       <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT718.gif"
        :class="['submit', { bottom: topShow,  'none': btn}]"
        @click="onSubmit"
      />
      <div class="info">
        <template>
          <div class="tag">
            芒果会员权益定向流量云盘版首月0.01元（次月起25元/月）
          </div>
          <div class="checked">
            <img
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd4_checked.png"
              v-show="checked"
              @click="checked = false"
            />
            <img
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd4_checkedNo.png"
              v-show="!checked"
              @click="checked = true"
            />
            <span class="test">我已详细阅读并同意</span>
            <span class="red" @click="handleScollBottom">《产品说明》</span>
          </div>
            <div class="tag call">
            客服热线：<u>4001189599</u>
          </div>
        </template>

        <!-- <div class="xq" @click="checkedShow = !checkedShow">
          <template v-if="!checkedShow">
            <span>查看详情</span>
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT714.png" >
          </template>
          <template v-else>
            <span>收起详情</span>
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT715.png" >
          </template>
        </div> -->
      </div>
      </div>
     <img
        class="other"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT707_4.png"
        alt=""
      />
    <div class="guize" ref="guize">
      <img
        class="title"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT703_2.png"
        alt=""
      />
      <img
        class="tab"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT716_1.png"
        alt=""
      />

     
      <template >
        1、本产品包含一个芒果TV VIP月会员、15GB芒果TV客户端定向流量、联通云盘白银会员（享4T云盘空间）。</br>
        2、本产品首月0.01元，次月起25元/月。</br>
        3、本产品为合约产品，合约期6个月，第6个月方可退订。合约期内退订，需补足首月优惠金额24.99元，且在原合约期结束后方能再次订购。</br>
        4、系统将发放的芒果会员充值到订购手机号码中，可直接登陆使用。</br>
        5、续订本产品，次月4日前，系统将会员充值到订购手机号码中，用户无需自行领取。</br>
        6、使用本产品时，请将芒果TV手机客户端升级至最新版本。</br>
        7、智慧沃家成员用户、主副卡用户订购当前定向流量产品，只限该成员使用，其他成员不共享。达量限速套餐用户订购当前定向流量产品，包内定向流量不提升原套餐限速阈值和网速，不能作为限速后解限的加速包使用，依然执行基础套餐限速阈值的约定。</br>
        8、订购、使用本产品请以本机实际使用号码为准。</br>
        9、本产品定向流量使用范围仅为客户端内视频格式内容，具体包括视频播放、视频缓存，客户端内的图片、文字、弹幕、第三方广告、主播类视频、部分短视频、IOS直播、视频分享、H5直播等不在定向流量范围内。流量当月有效，不可结转且不可转赠，并服从中国联通流量封顶统一限制。退订后定向流量仍可使用至当月底。</br>
        10、云盘：使用订购手机号码登录联通云盘 APP使用联通云盘白银会员权益。</br>
        11、退订方式：如需退订，可登录中国联通APP进行退订，中国联通APP-点击服务-我已订购中进行退订。亦可联系客服热线：4001189599或当地营业厅咨询退订。</br>
        12、客服热线：4001189599</br>
        13、产品服务：联通在线信息科技有限公司</br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品支持：浙江秋末信息科技有限公司。
      </template>
    </div>
    <img class="qudao" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTZJ_qdbs.png">

     <!-- 二确 -->
    <van-popup v-model="visibility" class="dialog-confirm">
      <div class="img"></div>
      <div class="footer">
        <slot name="footer">
          <img
            class="btn btn-close"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_jx2_05.png"
          />
          <img
            class="btn"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_jx2_06.png"
          />
          <img
            class="shou"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/yd_jx2_07.png"
          />
        </slot>
      </div>
    </van-popup>

  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  components: {},
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "zjlt_mgdx",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      checked: true,
      topShow: false,
      scrollTop: "",
      // 禁止订购
      btn: false,
      num: 1,
      checkedShow: false,
      cpsmShow: false,
      visibility: false,
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/LTZheJiang/videoRingtone25`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handlerscroll);
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      window.location.replace(
        that.tailJumpUrl(that.link, "QIUMORONGHE", phone, "退出")
      );
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (code.length < 6) {
          this.num = 2;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    phoneChange() {},
    // 验证码
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true; // 开始加载状态
        getVerificationCode(JSON.stringify(this.form))
          .then((res) => {
            if (res.code != 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              setTimeout(() => {
                window.location.replace(
                  this.tailJumpUrl(
                    this.link,
                    `QIUMORONGHE`,
                    this.form.phone,
                    "验证码下发失败"
                  )
                );
              }, 3000);
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 60;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          }).finally(() => {
            // 结束加载状态
            this.yzm_loading = false;
          });
      }
    },
    onSubmit() {
      if (this.topShow) {
        this.backTop();
        return;
      }
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$dialog.alert({ message: "请阅读并同意《产品说明》" });
      } else {
        // this.checked = true;
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        tradeOrder(JSON.stringify(this.form)).then((res) => {
          this.$toast.clear();
          // if (res.data.code != 0) {
          if (res.code !== 0) {
            this.$toast({
              duration: 8000,
              message: res.message || "提交失败",
            });
            setTimeout(() => {
              window.location.replace(
                this.tailJumpUrl(
                  this.link,
                  `QIUMORONGHE`,
                  this.form.phone,
                  "失败"
                )
              );
            }, 3000);
          } else {
            this.$dialog.alert({
              title: "提交成功",
              message: "具体结果，请以短信提示为准",
              messageAlign: "center",
            });
            setTimeout(() => {
              window.location.replace(
                this.tailJumpUrl(
                  this.link,
                  `QIUMORONGHE`,
                  this.form.phone,
                  "成功"
                )
              );
            }, 3000);
          }
        });
      }
    },
    handleScollBottom() {
      this.$nextTick(() => {
        const top = this.$refs.guize.offsetTop;
        document.documentElement.scrollTop = document.body.scrollTop = top;
      });
    },
    handlerscroll() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop >= 600) {
        this.topShow = true;
      } else {
        this.topShow = false;
      }
    },
    backTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
  },
};
</script>

<style lang="scss" scoped>
.mangguo {
  background-color: #e33c50;
  box-sizing: border-box;
  overflow: auto;

  .iconimg {
    width: 100%;
    // height: 780px;
  }

  .qudao {
    width: 100%;
  }
  .scroll_tips {
    width: 100%;
    margin: 0 auto 0;
    overflow: hidden;

    .scroll_box {
      animation: scroll 9s linear infinite;
      display: flex;
      align-items: center;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        padding: 12px 16px;
        font-size: 26px;
        color: white;
        font-weight: inherit;
        margin-left: 20px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-213%);
        }
      }
    }
  }

  .form {
    position: relative;
    // margin-top: 50px;
    padding: 22px 0 60px;
    // background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT708.png") 100% top/contain no-repeat,
    //  url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT710.png") center bottom/contain no-repeat,
    //  url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT709.png") center center/contain repeat;

    .one,
    .two {
      width: 400px;
      position: absolute;
      z-index: 1;
    }

    .one {
      transform: translate(120px, -20px);
    }

    .two {
      transform: translate(120px, 0px);
    }

    .info {
      margin-top: -26px;
      color: #fff;
    }

    .xq {
      font-size: 24px;
      color: #b40707;
      text-align: center;
      text-decoration: underline;

      img {
        padding-left: 8px;
        width: 17px;
      }
    }
  }

  ::v-deep .van-cell {
    // width: 586px;
    width: 630px;
    height: 100px;
    background-color: #ffffff;
    border-radius: 50px;
    margin: 70px auto 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 0 0 22px;
    // border: solid 2px #ae0003;

    // &.one-cell {
    //   margin-top: 70px;
    // }

    &.input-code {
      margin-top: 80px;

      // &.two-cell {
      //   margin-top: 59px;
      // }

      .code {
        width: 204px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #ae0003;
        font-size: 30px;
        font-weight: normal;
        border-left: solid 2px #ae0003;
        background-color: #ffd563;
      }
    }

    .van-field__body {
      height: 100%;
    }

    &::after {
      border: none;
    }

    .van-field__control {
      font-size: 38px;
      &::-webkit-input-placeholder {
        font-size: 38px;
        font-weight: normal;
        color: #c8c9cc;
      }
    }
  }

  .other {
    margin-top: 180px;
    width: 701px;
    margin: 0 auto;
    display: block;
  }

  .guize {
    margin: 37px 24px 40px;
    font-size: 24px;
    color: #700002;
    padding: 0 30px 36px;
    border-radius: 38px;
    font-weight: normal;
    line-height: 36px;
    background-color: #fff0d5;
    box-shadow: inset 0px 0px 8px 1px #ffffff;
    border-radius: 23px;
    border: solid 2px #900011;

    &-xq {
      font-size: 24px;
      color: #e33c50;
      text-align: center;
      padding-top: 17px;

      &.padding {
        padding-bottom: 25px;
      }

      img {
        padding-left: 8px;
        width: 17px;
      }
    }

    .title {
      width: 341px;
      height: 36px;
      margin: 0 auto;
      display: block;
      padding: 48px 0 33px;
    }

    .tab {
      width: 648px;
      display: block;
      margin: 0 auto;
    }
  }

  .submit {
    width: 626px;
    // height: 180px;
    display: block;
    margin: 120px auto 0;
    animation: 1s ease 0s infinite normal none running btnjump1;

    &.none {
      pointer-events: none;
    }

    &.bottom {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 634px;
      bottom: 70px;
    }
  }

  .tag,
  .checked {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    line-height: 36px;
  }

  .call {
    font-size: 28px;
  }

  .checked {
    display: flex;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    font-weight: normal;

    img {
      width: 28px;
      vertical-align: middle;
    }

    .test {
      padding-left: 10px;
    }

    .red {
      color: #fff;
    }
  }

  .deleteTip {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 1, 0, 0.6);
    z-index: 2;

    .tipbox {
      position: absolute;
      // width: 516px;
      height: 328px;
      top: 50%;
      left: 117px;
      right: 117px;
      margin: 0 auto;
      transform: translateY(-50%);
      background-color: #ffffff;
      color: #862523;
      text-align: center;
      background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LT7_dialog.png")
        100% top/contain no-repeat;

      .text {
        padding: 80px 0 60px;
      }

      .footerBut {
        img {
          width: 206px;
          display: inline-block;

          &:first-child {
            padding-right: 20px;
          }
        }
      }
    }
  }
  .dialog {
    &-confirm {
      box-sizing: border-box;
      width: 722px;
      min-height: 709px;
      background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/abc.png")
        100% top/contain no-repeat;

      .footer {
        padding: 600px 100px 0;
        display: flex;
        justify-content: space-evenly;
        position: relative;

        .shou {
          position: absolute;
          pointer-events: none;
          width: 71px;
          right: 196px;
          top: 638px;
        }

        .btn {
          width: 208px;

          &-close {
            width: 210px;
          }
          // height: 182px;
        }
      }
    }
  }
}
</style>
